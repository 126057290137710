import React from 'react';
import RenderingCanvas from './rendering-canvas/RenderingCanvas';
import GlobalStyle from './App.css';

function App() {
  return (
    <>
      <React.StrictMode>
        <GlobalStyle />
        <RenderingCanvas />;
      </React.StrictMode>
    </>
  );
}

export default App;
